import React, { useContext, useState } from 'react';
import { AuthenticationContext } from './Authentication';
import { toast } from 'react-toastify';
import Wkt from 'wicket';

function WorkAreaCreate() {

  const [uploadingStatus, setUploadingStatus] = useState<string>('');
  const [description, setDescription] = useState('');
  const [geoFilter, setGeoFilter] = useState('');
  const [zip, setZip] = useState<File>();
  const { userName, password, bronhouderCode } = useContext(AuthenticationContext);

  const onFileSelected = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setZip(file);
    }
  }

  const upload = () => {
    //validate
    if (!userName || !password) {
      toast.warning('Please enter a username and password');
      return;
    }
    if (!zip) {
      toast.warning("Please select a file", {});
      return;
    }
    if (!description) {
      toast.warning("Please enter a description");
      return;
    }

    if (geoFilter && !validateWKT(geoFilter)) {
      toast.warning("Please enter a valid GeoFilter");
      return;
    }

    //make form data
    const formData = new FormData();
    formData.append("userName", userName);
    formData.append("password", password);
    formData.append("zip", zip);
    formData.append("description", description);
    formData.append("geoFilter", geoFilter);
    formData.append("bronhouderCode", bronhouderCode)


    console.log(process.env.REACT_APP_TEST_TOOL_URL!);

    //upload
    setUploadingStatus("Uploading...");
    fetch(`${process.env.REACT_APP_TEST_TOOL_URL!}/WorkAreaUpload`, {
      method: "POST",
      body: formData
    }).then(async (response) => {
      if (response.ok) {
        toast.success("Upload successful");
        setDescription("");
        setZip(undefined);
      } else {
        toast.error("Upload failed:" + await response.text(), { autoClose: false });
      }
    }).catch((error) => {
      toast.error("Error:" + error);
    }).finally(() => {
      setUploadingStatus("");
    });

  }



  const validateWKT = (wktString: string) => {
    const wkt = new Wkt.Wkt();
    try {
      wkt.read(wktString);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <div className="main">
      <h5>Create a working area</h5>
      <div className="form">
        <div className="row">
          <label>Work Area Description: </label>
          <input id="name" type="text" value={description} onChange={e => setDescription(e.currentTarget.value)} />
        </div>
        <div className="row">
          <label>Geo filter (optional): </label>
          <input id="geoFilter" type="text" value={geoFilter} onChange={e => setGeoFilter(e.currentTarget.value)} />
        </div>
        <div className="row">
          <input type="file" className="file-input" id='fileUpload' onChange={onFileSelected} />

          <div className="file-upload">

            {zip?.name || "No file"}

            <button className="upload-btn" onClick={() => {
              const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
              if (fileUpload) {
                fileUpload.value = "";
                fileUpload.click();
              }
            }}>
              AttachFile
            </button>
          </div>
        </div>
        <div className="row">
          <label>{uploadingStatus} </label>
          <button onClick={() => upload()}>Upload work area</button>
        </div>
      </div>
    </div>
  )
}

export default WorkAreaCreate;