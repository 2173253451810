import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface GroupItem {
    code: string;
    name: string;
    bgtFeatureServiceUrl: string;
    bgtWorkAreaFeatureServiceLayerUrl: string;
}
interface Props {
    children: any;
}

const AuthenticationContext = React.createContext({ userName: '', password: '', bronhouderCode: '' });

const Authentication: React.FC<Props> = ({ children }) => {

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isUserValid, setIsUserValid] = useState(false);
    const [groupList, setGroupList] = useState<GroupItem[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<GroupItem>();

    useEffect(() => {
        if (isUserValid) {
            const formData = new FormData();
            formData.append("userName", userName);
            formData.append("password", password);
            fetch(`${process.env.REACT_APP_TEST_TOOL_URL!}/GroupList`, {
                method: "POST",
                body: formData
            }).then(async (response) => {
                if (response.ok) {
                    var groups = await response.json();
                    setGroupList(groups);
                } else {
                    toast.error("Upload failed:" + await response.text(), { autoClose: false });
                    logout();
                }
            }).catch((error) => {
                toast.error("Error:" + error);
            });
        }
    }, [isUserValid, userName, password]);

    useEffect(() => {
        const userName = localStorage.getItem("userName");
        const password = localStorage.getItem("password");
        if (userName && password) {
            setUserName(userName);
            setPassword(password);
            setIsUserValid(true);
        } else {
            setUserName('');
            setPassword('');
            setIsUserValid(false);
        }
    }, [isUserValid]);

    const login = () => {
        const formData = new FormData();
        formData.append("userName", userName);
        formData.append("password", password);
        fetch(`${process.env.REACT_APP_TEST_TOOL_URL!}/Auth`, {
            method: "POST",
            body: formData
        }).then(async (response) => {
            if (response.ok) {
                localStorage.setItem("userName", userName);
                localStorage.setItem("password", password);
                setIsUserValid(true);
            } else {
                setIsUserValid(false);
                toast.error("Login failed:" + (await response.json())['error']);
            }
        }).catch((error) => {
            toast.error("Error:" + error);
        });
    }

    const logout = () => {
        localStorage.removeItem("userName");
        localStorage.removeItem("password");
        setIsUserValid(false);
    }

    const onGroupSelected = (groupCode: string) => {
        setSelectedGroup(groupList.find(item => item.code === groupCode));
    }

    return (
        <AuthenticationContext.Provider value={{ userName, password, bronhouderCode: selectedGroup?.code ?? '' }}>
            <div className="main">
                {!isUserValid && <div className="form">
                    <div className="row">
                        <label>UserName: </label>
                        <input type="text" value={userName} onChange={e => setUserName(e.currentTarget.value)} />
                    </div>
                    <div className="row">
                        <label>Password: </label>
                        <input type="password" value={password} onChange={e => setPassword(e.currentTarget.value)} />
                    </div>
                    <button onClick={() => login()}>Login</button>
                </div>
                }
                {isUserValid &&
                    <div className="form">
                        <div className="row">
                            <label>Groups: </label>
                            <select value={selectedGroup?.code} onChange={e => onGroupSelected(e.currentTarget.value)}>
                                {!selectedGroup?.code && <option value="">Select...</option>}
                                {groupList.map(item => <option key={item.code} value={item.code}>{`${item.name}(${item.code})`}</option>)}
                            </select>
                        </div>
                        {selectedGroup && <>
                            <div className="row">
                                <label>bgtFeatureServiceUrl: </label>
                                <p style={{ fontSize: 12 }}>{selectedGroup?.bgtFeatureServiceUrl}</p>
                            </div>
                            <div className="row">
                                <label>bgtWorkAreaFeatureServiceLayerUrl: </label>
                                <p style={{ fontSize: 12 }}>{selectedGroup?.bgtWorkAreaFeatureServiceLayerUrl}</p>
                            </div>
                        </>}
                        <button onClick={() => logout()}>Logout</button>
                    </div>
                }
            </div>
            <hr />
            {isUserValid && selectedGroup && children}
        </AuthenticationContext.Provider>
    );
};

export { AuthenticationContext, Authentication };