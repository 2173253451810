import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import WorkAreaUpload from './WorkAreaCreate';
import UpdateMessageUpload from './UpdateMessageUpload';
import { Authentication } from './Authentication';
import { ToastContainer } from 'react-toastify';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import WorkAreaList from './WorkAreaList';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <div>
      <h2>Cadac Catalog TestTool</h2>
      <hr />
      <Authentication>
        <WorkAreaUpload />
        <hr />
        <UpdateMessageUpload />
        <hr />
        <WorkAreaList />
      </Authentication>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
