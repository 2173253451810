import { useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from './Authentication';
import { toast } from 'react-toastify';
import { RxUpdate } from "react-icons/rx";

interface WorkAreaItem {
    id: string;
    versionName: string;
    name: string;
    status: string;
    createdBy: string;
    referenceNumber: string;
    hasUpdateMessage?: number;
}
export interface IWorkAreaListProps {
}

export default function WorkAreaList(props: IWorkAreaListProps) {
    const { userName, password, bronhouderCode } = useContext(AuthenticationContext);
    const [workAreaList, setWorkAreaList] = useState<WorkAreaItem[]>([]);
    const [isLoadingWorkAreaList, setIsLoadingWorkAreaList] = useState<boolean>(false);
    const loadData = () => {
        const formData = new FormData();
        formData.append("userName", userName);
        formData.append("password", password);
        formData.append("bronhouderCode", bronhouderCode);
        setIsLoadingWorkAreaList(true);
        fetch(`${process.env.REACT_APP_TEST_TOOL_URL!}/WorkAreaList`, {
            method: "POST",
            body: formData
        }).then(async (response) => {
            if (response.ok) {
                var items = await response.json();
                setWorkAreaList(items);
            } else {
                toast.error("Get list failed:" + (await response.text()), { autoClose: false });
            }
        }).catch((error) => {
            toast.error("Error:" + error, { autoClose: false });
        }).finally(() => {
            setIsLoadingWorkAreaList(false);
        })
    }

    const exporterTestFileUpload = (workAreaId: string, versionName: string, file: File) => {
        //validate
        if (!userName || !password) {
            toast.warning('Please enter a username and password');
            return;
        }

        if (!file) {
            toast.warning("Please select a file", {});
            return;
        }

        //make form data
        const formData = new FormData();
        formData.append("userName", userName);
        formData.append("password", password);
        formData.append("zip", file);
        formData.append("workAreaId", workAreaId);
        formData.append("versionName", versionName);
        formData.append("bronhouderCode", bronhouderCode)

        setWorkAreaList(workAreaList.map(item => item.id === workAreaId ? { ...item, status: "Uploading..." } : item));

        fetch(`${process.env.REACT_APP_TEST_TOOL_URL!}/ExporterTestFileUpload`, {
            method: "POST",
            body: formData
        }).then(async (response) => {
            if (response.ok) {
                toast.success("Upload successful");
            } else {
                toast.error("Upload failed:" + (await response.text()), { autoClose: false });
            }
        }).catch((error) => {
            toast.error("Error:" + error);
        }).finally(() => {
            loadData();
        });
    }

    const exporterFeedBackApprove = (referenceNumber: string, bronhouderCode: string) => {
        //make form data
        const formData = new FormData();
        formData.append("userName", userName);
        formData.append("password", password);
        formData.append("referenceNumber", referenceNumber);
        formData.append("bronhouderCode", bronhouderCode);

        fetch(`${process.env.REACT_APP_TEST_TOOL_URL!}/ExporterFeedbackApprove`, {
            method: "POST",
            body: formData
        }).then(async (response) => {
            if (response.ok) {
                toast.success("Approve successful");
            } else {
                toast.error("Approve failed:" + (await response.text()), { autoClose: false });
            }
        }).catch((error) => {
            toast.error("Error:" + error);
        }).finally(() => {
            loadData();
        });
    }

    const exporterFeedbackReject = (referenceNumber: string, bronhouderCode: string) => {
        //make form data
        const formData = new FormData();
        formData.append("userName", userName);
        formData.append("password", password);
        formData.append("referenceNumber", referenceNumber);
        formData.append("bronhouderCode", bronhouderCode);

        fetch(`${process.env.REACT_APP_TEST_TOOL_URL!}/ExporterFeedbackReject`, {
            method: "POST",
            body: formData
        }).then(async (response) => {
            if (response.ok) {
                toast.success("Reject successful");
            } else {
                toast.error("Reject failed:" + (await response.text()), { autoClose: false });
            }
        }).catch((error) => {
            toast.error("Error:" + error);
        }).finally(() => {
            loadData();
        });

    }

    useEffect(() => {
        if (bronhouderCode) {
            loadData();
        }
    }, [bronhouderCode])
    return (
        (<div>
            <div style={{ textAlign: 'right', padding: '10px' }}>
                <button onClick={loadData}>Load</button>
            </div>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Version name</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Name</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Status</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Created by</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Control</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoadingWorkAreaList && <tr><td colSpan={5} style={{ padding: '8px', textAlign: 'center' }}>Loading...</td></tr>}
                    {!isLoadingWorkAreaList && workAreaList.map((item) => (
                        <tr key={item.id} style={{ borderBottom: '1px solid #ddd' }}>
                            <td style={{ padding: '8px', textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.versionName}</td>
                            <td style={{ padding: '8px', textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</td>
                            <td style={{ padding: '8px', textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.hasUpdateMessage ? <RxUpdate style={{ marginRight: '5px', fontSize: 20, color: 'red' }} /> : <></>}{item.status}</td>
                            <td style={{ padding: '8px', textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.createdBy}</td>
                            <td style={{ padding: '8px', textAlign: 'left' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <input style={{ display: 'none' }} id={`testFileUpload${item.id}`} type="file" onChange={e => {
                                        e.target.files![0] && exporterTestFileUpload(item.id, item.versionName, e.target.files![0]);
                                        e.target.value = '';
                                    }} />
                                    <a id={`downloadZip${item.id}`} href={`${process.env.REACT_APP_TEST_TOOL_URL!}/ExporterFileDownload?workareaid=${item.id}`} style={{ display: 'none' }}>_</a>
                                    <button style={{ textAlign: 'left' }} onClick={() => document.getElementById(`testFileUpload${item.id}`)!.click()}>Upload test file</button>
                                    {item.status === "wait_stuf_file_created_for_lv" && <>
                                        <button style={{ textAlign: 'left' }} onClick={() => exporterFeedBackApprove(`${item.referenceNumber}`, bronhouderCode)}>Approve</button>
                                        <button style={{ textAlign: 'left' }} onClick={() => exporterFeedbackReject(`${item.referenceNumber}`, bronhouderCode)}>Reject</button></>}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>)
    );
}
