import React, { useContext, useState } from 'react';
import { AuthenticationContext } from './Authentication';
import { toast } from 'react-toastify';


function UpdateMessage() {

  const [uploadingStatus, setUploadingStatus] = useState<string>('');
  const [zip, setZip] = useState<File | undefined>(undefined);
  const { userName, password, bronhouderCode } = useContext(AuthenticationContext);

  const onFileSelected = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setZip(file);
    }
  }

  const upload = () => {
    //validate
    if (!userName || !password) {
      toast.warning("Please enter a username and password");
      return;
    }
    if (!zip) {
      toast.warning("Please select a file");
      return;
    }

    //make form data
    const formData = new FormData();
    formData.append("userName", userName);
    formData.append("password", password);
    formData.append("zip", zip);
    formData.append("bronhouderCode", bronhouderCode)

    //upload
    setUploadingStatus("Uploading...");
    fetch(`${process.env.REACT_APP_TEST_TOOL_URL!}/UpdateMessageUpload`, {
      method: "POST",
      body: formData
    }).then(async (response) => {
      if (response.ok) {
        toast.success("Upload successful");
        setZip(undefined);
      } else {
        toast.error("Upload failed:" + await response.text(), { autoClose: false });
      }
    }).catch((error) => {
      toast.error("Error:" + error, { autoClose: false });
    }).finally(() => {
      setUploadingStatus("");
    });

  }

  return (
    <div className="main">
      <h5>Upload update message</h5>
      <div className="form">
        <div className="row">
          <input type="file" className="file-input" id='updateMessageUpload' onChange={onFileSelected} />

          <div className="file-upload">

            {zip?.name || "No file"}

            <button className="upload-btn" onClick={() => {
              const fileUpload = document.getElementById('updateMessageUpload') as HTMLInputElement;
              if (fileUpload) {
                fileUpload.value = "";
                fileUpload.click();
              }
            }}>
              AttachFile
            </button>
          </div>
        </div>
        <div className="row">
          <label>{uploadingStatus} </label>
          <button onClick={() => upload()}>Upload update message</button>
        </div>
      </div>
    </div>
  )
}

export default UpdateMessage;